import React from "react"
import Layout from "../components/layout"
import { Link, graphql, useStaticQuery } from "gatsby"

import "../styles/pages/blog.scss"
import Head from "../components/head"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: [publishedDate], order: DESC }) {
        edges {
          node {
            title
            author {
              firstName
              lastName
              fullName
            }
            publishedDate(formatString: "MMMM Do, YYYY")
            updatedAt(formatString: "MMMM Do, YYYY")
            postIcon {
              url
              title
            }
            slug
            id
            isDraft
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
          author
        }
      }
    }
  `)

  return (
    <Layout>
      <Head title={`Blog | ${data.site.siteMetadata.author}`} />
      <div className="my-4 sm:my-12 px-4">
        <h1 className="text-zinc-100 text-5xl font-bold mb-4">Posts</h1>
        <p className="text-zinc-400 text-lg mb-4">
          The contents of my posts vary between; technical things, customer
          experience, growth and automations.
        </p>
        <ol>
          {data.allContentfulBlogPost.edges.map(edge => {
            return (
              <li
                className={`bg-zinc-800 active:opacity-80 sm:bg-transparent sm:mt-0 mt-3 hover:bg-zinc-700/50 hover:shadow-md px-5 py-4 rounded-md align-top relative flex ring-1 ring-zinc-900/10 focus:outline-none focus:ring-2  shadow-sm  ${
                  edge.node.isDraft ? "placeholder-opacity-50" : ""
                }`}
                key={`${edge.node.id}`}
              >
                <img
                  className="w-12 mb-auto mt-1"
                  src={edge.node.postIcon.url}
                  alt={edge.node.title}
                />

                <div className="flex flex-row align-top ml-3">
                  <div className="flex-col">
                    <h2 className="text-zinc-200 text-2xl mb-1 group font-semibold">
                      {edge.node.title}{" "}
                      {edge.node.isDraft && (
                        <span className="text-indigo-300">#draft</span>
                      )}
                    </h2>
                    <p className="text-zinc-300 group text-sm">
                      Updated: {edge.node.updatedAt} (Published:{" "}
                      {edge.node.publishedDate})
                    </p>
                  </div>
                </div>
                <Link
                  className="absolute inset-0"
                  to={`${edge.node.isDraft ? "#" : `/${edge.node.slug}/`}`}
                ></Link>
              </li>
            )
          })}
        </ol>
      </div>
    </Layout>
  )
}

export default BlogPage
